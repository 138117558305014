import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './components/Login'
import Register from './components/Register'
import UserDetail from './components/UserDetail'
import Homepage from './components/Homepage'
import Navbar from './components/Navbar'
import REBACalculation from './components/REBACalculation'
import AboutUs from './components/AboutUs'
// import REBACalc from './components/REBACalc';
// import UploadComponent from './components/UploadComponent';
import './index.css' // Import the styles.css file

function App() {
  return (
    <Router>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/user" element={<UserDetail />} />
          <Route path="/rebacalc" element={<REBACalculation />} />
          <Route path="/about" element={<AboutUs />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
