import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
function LoginUser() {
    const apiUrl = process.env.REACT_APP_BACKEND_URL;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const handleLogin = () => {
        // Send login request to the backend
        axios
            .post(`${apiUrl}/login`, { email, password })
            .then(response => {
                setMessage(response.data.message);
                const { username, email, token } = response.data;
                localStorage.setItem('username', username);
                localStorage.setItem('email', email);
                localStorage.setItem('token', token);
                setIsAuthenticated(true)
                // Redirect to homepage using navigate
                navigate('/user'); // Replace '/' with the homepage URL if needed
            })
            .catch(error => {
                console.error(error);
                setMessage('Error logging in. Please try again.');
            });
    };
    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    };

    return (
        <div>
            <h2>Login</h2>
            <div>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
               {!isAuthenticated ? (
                <div>
                    <h1>Login Page</h1>
                    <button onClick={handleLogin}>Login</button>
                </div>
            ) : (
                <div>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            )}
                {message && <p>{message}</p>}
            </div>
        </div>
    );
}
export default LoginUser;
