import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import {
  Button,
  Paper,
  Typography,
  Tooltip,
  Link,
  Container,
  Grid,
  Box,
} from '@mui/material'
import {
  Slider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import FeedBackForm from './FeedBackForm' // Import the FormComponent

const slider_labels = [
  { value: 0.5, label: 'Low' },
  { value: 0.75, label: 'Medium' },
  { value: 0.99, label: 'High' },
]

const REBACalculation = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_URL
  const [file, setFile] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedForceValue, setForceValue] = useState(5) // Default to '5' which is the lowest weight of 5 KGs
  const [confidence_value, setConfidenceValue] = useState(0.5) // Default to 'Medium'
  const [taskId, setTaskId] = useState(null)
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingWheelState, setLoadingWheelState] = useState(false)
  const [result, setResult] = useState(null)
  const [showGrid, setShowGrid] = useState(false) // Example initial state
  const gridRef = useRef(null)
  const wikiURL = 'https://en.wikipedia.org/wiki/Manual_handling_of_loads'

  // Handle file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0])
    const file = event.target.files[0]
    console.log('Selected File:', file)
    setSelectedFile(file)
    setLoading(true)

    // Optional: You can also show a preview of the selected image
    const reader = new FileReader()
    reader.onloadend = () => {
      // Update state or display preview image
      // setPreviewImage(reader.result);
    }
    reader.readAsDataURL(file)
  }

  // Handle the Force selection in the Radiobox
  const handleForceChange = (event) => {
    setForceValue(event.target.value)
  }

  // Handle the Confidence Selection in the Slider
  const handleConfidenceChange = (event) => {
    setConfidenceValue(event.target.value)
  }

  // Handle file upload
  const handleUpload = async () => {
    if (!file) return
    const formData = new FormData()
    formData.append('file', file)
    formData.append('force_weight', selectedForceValue)
    formData.append('confidence_threshold', confidence_value)

    try {
      const response = await axios.post(
        `${apiUrl}/process-image`,
        formData,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      setTaskId(response.data.task_id)
      setStatus('Processing...') // Initial status after upload
      setLoading(true)
      setLoadingWheelState(true)
    } catch (error) {
      console.error('Error uploading image:', error)
      setStatus('Error uploading image')
    }
  }

  // Check task status
  useEffect(() => {
    if (!taskId) return
    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(`${apiUrl}/task-status/${taskId}`)
        const taskStatus = response.data.status
        if (taskStatus === 'Processing') {
          setStatus('Processing...')
          setLoading(true)
          setLoadingWheelState(true)
          setShowGrid(false)
        } else {
          setStatus(taskStatus) // Completed or Failed
          clearInterval(intervalId) // Stop checking after completion
          setResult(response.data.result)
          console.log('RESULT::::', result)
          // Setting loading as FALSE as finally the page has loaded
          setLoading(false)
          setLoadingWheelState(false)
          // Setting showGrid to true to render the grid
          setShowGrid(true)
        }
      } catch (error) {
        console.error('Error checking task status:', error)
        setStatus('Error checking task status')
        clearInterval(intervalId)
      } finally {
      }
    }, 5000) // Check every 5 seconds

    return () => clearInterval(intervalId) // Cleanup interval on component unmount
  }, [taskId])

  useEffect(() => {
    console.log('showGrid:', showGrid)
    console.log('gridRef.current:', gridRef.current)
    if (showGrid && gridRef.current) {
      const timeoutId = setTimeout(() => {
        gridRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        gridRef.current.focus()
      }, 0)
      return () => clearTimeout(timeoutId) // Cleanup timeout on dependency change
    }
  }, [showGrid])

  return (
    <Container>
      <Typography variant="h4">Upload Image to Calculate REBA Score</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} sm={6} md={6} lg={6}>
          {/* <TextField type="file" fullWidth onChange={handleFileChange} /> */}
          <input
            type="file"
            accept="image/*"
            capture="environment" // Try to use the back camera on devices
            style={{ display: 'none' }} // Hide the input visually
            id="cameraInput"
            onChange={handleFileChange}
          />
          <label htmlFor="cameraInput">
            <Tooltip
              title="We dont save your images.Take a clear picture of a human performing an action. Taking images from distance or of multiple items might not help to get accurate results."
              arrow
            >
              <Button
                variant="contained"
                color="primary"
                component="span"
                style={{ marginTop: '20px' }}
                startIcon={<PhotoCameraIcon />} // Add camera icon
              >
                Snap a Human
              </Button>
            </Tooltip>
          </label>
          {selectedFile && (
            <div style={{ marginTop: '20px' }}>
              <p>Selected File: {selectedFile.name}</p>
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Selected File Preview"
                style={{ maxWidth: '100%', maxHeight: '200px' }}
              />
            </div>
          )}
          <Tooltip
            title="If your image is clear in capturing the person in action, you can opt for higher confidence. It is preferable to go with low or medium confidence level."
            arrow
          >
            <Typography
              id="discrete-slider"
              gutterBottom
              style={{ marginTop: '20px' }}
            >
              Minimum Detection Confidence
            </Typography>
          </Tooltip>
          <Box sx={{ width: '300px', maxWidth: '100%', marginTop: '20px' }}>
            {' '}
            {/* Limiting the width of the slider */}
            <Slider
              value={confidence_value}
              onChange={handleConfidenceChange}
              aria-labelledby="discrete-slider"
              step={0.01}
              marks={slider_labels}
              min={0.5}
              max={0.99}
              valueLabelDisplay="auto"
            />
          </Box>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <Tooltip
                title="Select the Force/Load on the subject. The higher the value, the more force is applied on the subject."
                arrow
              >
                <FormLabel component="legend">
                  Add Force/Load on the subject
                </FormLabel>
              </Tooltip>
              <RadioGroup
                aria-label="options"
                name="options"
                value={selectedForceValue}
                onChange={handleForceChange}
              >
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="< 11 lbs (~5 KGs)>"
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="11 to 22 lbs (~5 KGs to ~10 KGs)"
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="22 lbs (~10 KGs)"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" onClick={handleUpload}>
              Upload and Process
            </Button>
            <br />
            <span class="disclaimer">
              * Note that we dont store/save your images.
            </span>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <img
                src={
                  process.env.PUBLIC_URL + '/' + 'images/REBA_calculations.jpeg'
                }
                alt="REBA Reference Image"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography align="center">
                Reba Calculation Manual | Wiki Link{' '}
                <Link href={`${wikiURL}`}>{wikiURL}</Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {loadingWheelState && (
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
      </Grid>
      {!loading && (
        <Grid item xs={12} sm={6} ref={gridRef}>
          {result && (
            <div>
              <img
                src={`data:image/jpeg;base64,${result.img}`}
                alt="Processed"
                style={{ padding: '20px' }}
              />
            </div>
          )}
        </Grid>
      )}
      {!loading && (
        <Grid item xs={12} sm={6}>
          <div>
            <p>
              <h2>REBA Score:</h2>
            </p>
          </div>
        </Grid>
      )}
      {!loading && (
        <Grid item xs={12} sm={6}>
          <Paper style={{ padding: 16 }}>
            {result && (
              <Typography
                variant="h6"
                style={{ fontWeight: 'bold', fontSize: '24px' }}
              >
                {result.final_reba_score}
              </Typography>
            )}
            {result && (
              <Typography
                variant="h6"
                sx={{
                  color: result.reba_score_eval_msg.color,
                  fontWeight: 'bold',
                  fontSize: '24px',
                }}
              >
                {result.reba_score_eval_msg.msg}
              </Typography>
            )}
          </Paper>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid item xs={12} sm={6}>
          <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
            <p>STEP 1: Locate Neck Position</p>
          </div>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid container spacing={2}>
          {/* First Column Spanning Two Rows */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid black',
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + '/' + 'images/neck_angle.png'}
                    alt="neck_angle"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2}>
                  {/* First Row Data */}
                  <Grid item xs={4}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Neck Angle
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Angle Score
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Tilt Score
                    </Typography>
                  </Grid>

                  {/* Second Row Data */}
                  {result && (
                    <React.Fragment key="1">
                      <Grid item xs={4}>
                        <Typography>{result.neck_angle}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{result.neck_score}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{result.tilt_score}</Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <br />
      {!loading && (
        <Grid item xs={12} sm={6}>
          <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
            <p>STEP 2: Locate Trunk Position</p>
          </div>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid container spacing={2}>
          {/* First Column Spanning Two Rows */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid black',
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + '/' + 'images/trunk.png'}
                    alt="trunk_angle"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2}>
                  {/* First Row Data */}
                  <Grid item xs={4}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Bend Angle
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Trunk Score
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Twist Score
                    </Typography>
                  </Grid>

                  {/* Second Row Data */}
                  {result && (
                    <React.Fragment key="1">
                      <Grid item xs={4}>
                        <Typography>{result.bend}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{result.bend_score}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{result.twist_score}</Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid item xs={12} sm={6}>
          <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
            <p>STEP 3: Leg Score</p>
          </div>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid container spacing={2}>
          {/* First Column Spanning Two Rows */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid black',
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + '/' + 'images/legs.png'}
                    alt="legs_score"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2}>
                  {/* First Row Data */}
                  <Grid item xs={4}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Leg Angle
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Leg Score
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Adjust
                    </Typography>
                  </Grid>

                  {/* Second Row Data */}
                  {result && (
                    <React.Fragment key="1">
                      <Grid item xs={4}>
                        <Typography>{result.leg_angle}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{result.leg_score}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{result.leg_twist_score}</Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid container direction="column" spacing={1}>
          <Typography variant="body1">
            Table A Posture Score: {result.table_A_posture_score}
          </Typography>
          <Typography variant="body1">
            Force/Load Score: {result.load_score}
          </Typography>
          <Typography variant="body1">
            Final Table A Score: {result.table_A_score}
          </Typography>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid item xs={12} sm={6}>
          <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
            <p>STEP 4: Locate Upper Arm Position</p>
          </div>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid container spacing={2}>
          {/* First Column Spanning Two Rows */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid black',
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + '/' + 'images/upper_arm.png'}
                    alt="upper_arm_score"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  {/* First Row Data */}
                  <Grid item xs={6}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Upper Arm Angle
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Angle Score
                    </Typography>
                  </Grid>

                  {/* Second Row Data */}
                  {result && (
                    <>
                      <Grid item xs={6}>
                        <Typography>{result.uarm_angle}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{result.upper_arm_score}</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid item xs={12} sm={6}>
          <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
            <p>STEP 5: Locate Lower Arm Position</p>
          </div>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid container spacing={2}>
          {/* First Column Spanning Two Rows */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid black',
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + '/' + 'images/lower_arm.png'}
                    alt="lower_arm_score"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2}>
                  {/* First Row Data */}
                  <Grid item xs={6}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Lower Arm Angle
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Angle Score
                    </Typography>
                  </Grid>

                  {/* Second Row Data */}
                  {result && (
                    <React.Fragment key="1">
                      <Grid item xs={6}>
                        <Typography>{result.larm_angle}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{result.lower_arm_score}</Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid item xs={12} sm={6}>
          <div style={{ fontWeight: 'bold', fontSize: '24px' }}>
            <p>STEP 6: Locate Wrist Position</p>
          </div>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid container spacing={2}>
          {/* First Column Spanning Two Rows */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid black',
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + '/' + 'images/wrist_angle.png'
                    }
                    alt="wrist_angle_score"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2}>
                  {/* First Row Data */}
                  <Grid item xs={6}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Wrist Angle
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Angle Score
                    </Typography>
                  </Grid>

                  {/* Second Row Data */}
                  {result && (
                    <React.Fragment key="1">
                      <Grid item xs={6}>
                        <Typography>{result.wrist_angle}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{result.wrist_angle_score}</Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <br />
      {!loading && (
        <Grid container direction="column" spacing={1}>
          <Typography variant="body1">
            Table B Posture Score: {result.arm_posture_score}
          </Typography>
          <Typography variant="body1">
            Coupling Score: {result.arm_coupling_score}
          </Typography>
          <Typography variant="body1">
            Final Table B Score: {result.table_b_score}
          </Typography>
          <Typography variant="body1">
            Final Table C Score: {result.final_reba_score}
          </Typography>
        </Grid>
      )}
      <br />
      <br />
      <br />
      {!loading && (
        <div style={{ textAlign: 'justify', width: '100%' }}>
          <FeedBackForm />
        </div>
      )}
    </Container>
  )
}

export default REBACalculation
