import React, { useRef, useEffect, useState } from 'react'
import axios from 'axios'
import {
  Box,
  Button,
  Collapse,
  TextField,
  Typography,
  Link,
  Snackbar,
  Alert,
} from '@mui/material'

const FeedBackForm = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_URL
  const contact_email = process.env.REACT_APP_CONTACT_US_EMAIL
  const contact_num = process.env.REACT_APP_CONTACT_US_PH_NO
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [user_name, setName] = useState('')
  const [user_email, setEmail] = useState('')
  const [user_ph_num, setPhoneNumber] = useState('')
  const [user_comment, setComments] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [phoneNumberError, setphoneNumberError] = useState(false)
  const [showThankYou, setShowThankYou] = useState(false)
  const firstFieldRef = useRef(null)

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen)
    setShowThankYou(false)
  }

  const validateEmail = (email) => {
    // Regular expression for validating email format
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  // Function to validate phone number
  const validatePhoneNumber = (number) => {
    // Example regex for US phone numbers, modify as needed
    const phoneRegex =
      /^(\+1\s?)?(\([0-9]{3}\)|[0-9]{3})([\s\-])?[0-9]{3}([\s\-])?[0-9]{4}$/
    return phoneRegex.test(number)
  }

  const handleValidEmail = (e) => {
    const { value } = e.target
    setEmail(value)
    setEmailError(!validateEmail(value))
  }

  // Function to handle input changes
  const handleValidPhoneNum = (e) => {
    const value = e.target.value
    setPhoneNumber(value)
    setphoneNumberError(!validatePhoneNumber(value))
  }

  useEffect(() => {
    if (isFormOpen && firstFieldRef.current) {
      firstFieldRef.current.focus()
      setTimeout(() => {
        const rect = firstFieldRef.current.getBoundingClientRect()
        window.scrollTo({
          top: rect.top + window.scrollY - 200, // Adjust 20 to the amount of offset needed
          behavior: 'smooth',
        })
      }, 200) // Adjust the delay as necessary
    }
  }, [isFormOpen])

  const handleSubmit = async () => {
    // Handle form submission if email is valid
    if (!emailError) {
      // Handle the form submission logic here
      console.log('Name:', user_name)
      console.log('Email:', user_email)
      console.log('Email:', user_ph_num)
      console.log('Comments:', user_comment)

      try {
        // Make a POST request to your backend endpoint
        const feedback_response = axios
          .post(`${apiUrl}/feedback`, {
            user_name,
            user_email,
            user_ph_num,
            user_comment,
          })
          .then((response) => {
            console.log('Response Message: ', response.data.message)
          })
          .catch((error) => {
            console.error('Error submitting feedback. We are looking into it')
          })
        // Clear form fields after successful submission
        setName('')
        setEmail('')
        setPhoneNumber('')
        setComments('')
        setShowThankYou(true)
      } catch (error) {
        // Handle error
        console.error('Error submitting form:', error)
      }
    }
  }
  return (
    <Box
      sx={{ width: '100%', padding: '20px', textAlign: 'center' }}
      xs={{ width: '70%', padding: '20px', textAlign: 'center' }}
      sm={{ width: '100%', padding: '20px', textAlign: 'center' }}
      md={{ width: '100%', padding: '20px', textAlign: 'center' }}
      lg={{ width: '100%', padding: '20px', textAlign: 'center' }}
    >
      <Button variant="contained" color="primary" onClick={toggleForm}>
        {isFormOpen ? 'Close Form' : 'Send Us Your Feedback'}
      </Button>
      <Collapse in={isFormOpen}>
        <Box sx={{ marginTop: '20px' }}>
          <TextField
            inputRef={firstFieldRef}
            variant="outlined"
            label="Name"
            value={user_name}
            onChange={(e) => setName(e.target.value)}
            sx={{ marginRight: '10px', marginBottom: '10px' }}
            fullWidth
          />
          <TextField
            variant="outlined"
            label="Email"
            value={user_email}
            onChange={handleValidEmail}
            error={emailError}
            helperText={emailError ? 'Enter valid Email!' : ''}
            sx={{ marginRight: '10px', marginBottom: '10px' }}
            fullWidth
          />
          <TextField
            variant="outlined"
            label="Phone Number"
            value={user_ph_num}
            onChange={handleValidPhoneNum}
            error={phoneNumberError}
            helperText={phoneNumberError ? 'Enter valid Phone Number!' : ''}
            sx={{ marginRight: '10px', marginBottom: '10px' }}
            fullWidth
          />
          <TextField
            variant="outlined"
            label="Comments"
            value={user_comment}
            onChange={(e) => setComments(e.target.value)}
            sx={{ marginRight: '10px', marginBottom: '10px' }}
            fullWidth
            multiline
            rows={4}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <br />
          <br />
          <br />
          <Typography variant="body1">
            For further queries, kindly email us at{' '}
            <Link href={`mailto:${contact_email}`} target="_blank">
              {contact_email}
            </Link>{' '}
            or reach out to us at{' '}
            <Link href={`tel:${contact_num}`} target="_blank">
              {contact_num}
            </Link>
            .
          </Typography>
        </Box>
      </Collapse>
      <Snackbar
        open={showThankYou}
        autoHideDuration={6000}
        onClose={() => setShowThankYou(false)}
      >
        <Alert
          onClose={() => setShowThankYou(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Thank you for your feedback!
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default FeedBackForm
