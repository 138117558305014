import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

function Navbar() {
  const logo_path = process.env.REACT_APP_AIJIT_LOGO_PATH

  return (
    <nav className="navbar">
      <div className="image-container">
        <Link to="/">
          <img
            src={logo_path}
            alt="Description of Image"
            className="right-aligned-image"
          />
        </Link>
      </div>
      <div className="nav-links">
        <NavLink to="/" exact activeClassName="active">
          Home
        </NavLink>
        <NavLink to="/rebacalc" activeClassName="active">
          REBA-Calculator
        </NavLink>
        <NavLink to="/about" activeClassName="active">
          About-Us
        </NavLink>
      </div>
    </nav>
  )
}
export default Navbar
