import React, { useEffect, useState } from 'react'

function UserDetail() {
  const apiUrl = process.env.REACT_APP_BACKEND_URL
  const [userData, setUserData] = useState(null)
  useEffect(() => {
    // Fetch user data from the API endpoint
    fetch(`${apiUrl}/userservice`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setUserData(data))
      .catch((error) => console.error(error))
  }, [])
  console.log('USERDATA: ', userData)
  // const { username } = userData['username'];
  // console.log("USERNAME: ", username)
  // localStorage.setItem('username', username);
  // localStorage.setItem('email', email);
  const username = localStorage.getItem('username')
  const email = localStorage.getItem('email')
  return (
    <div>
      <h1>Welcome to the User Detail Page</h1>
      {userData && (
        <p>
          Welcome {username}, {email} to the User Detail Page!
        </p>
      )}
    </div>
  )
}
export default UserDetail
