import React from 'react'
import { Container, Typography, Box, Link, Grid } from '@mui/material'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'

const contact_email = process.env.REACT_APP_CONTACT_US_EMAIL
const contact_num = process.env.REACT_APP_CONTACT_US_PH_NO

const AboutUs = () => {
  return (
    <Container>
      <Box my={4}>
        <img
          src={'images/logo512.png'}
          alt="Header"
          style={{
            width: '35%',
            height: '5%',
            display: 'block',
            margin: '0 auto',
          }}
        />

        <Typography variant="body1" paragraph>
          Welcome to <strong>AIJIT</strong>, where innovation meets expertise.
          We are a dynamic team of industry experts committed to delivering
          cutting-edge, AI solutions tailored to your business needs. Our
          mission is to propel your growth and enhance efficiencies through the
          strategic application of advanced technologies.
        </Typography>
        <Typography variant="body1" paragraph>
          <h3>Who We Are ?</h3>
          At <strong>AIJIT</strong>, our team comprises of experts with a
          profound understanding of industrial design, ergonomics, Lean Six
          Sigma, and other industry standards. We believe that by integrating
          these disciplines with artificial intelligence and machine learning,
          we can unlock new possibilities and drive significant improvements for
          our clients.
        </Typography>

        <Typography variant="body1" paragraph>
          <h3>Our Core Competencies</h3>
          <ul>
            <li>
              Industrial Design & Ergonomics: We create solutions that
              prioritize user experience and functionality, ensuring that every
              design we deliver is both practical and innovative.{' '}
            </li>
            <li>
              Lean Six Sigma: Our expertise in Lean Six Sigma methodologies
              enables us to optimize processes, reduce waste, and enhance
              quality, ensuring that your operations run smoothly and
              efficiently.{' '}
            </li>
            <li>
              Advanced Technologies: We leverage the latest advancements in AI
              and ML to provide intelligent solutions that accelerate growth,
              streamline operations, and improve decision-making processes.
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          <h3>Our Commitment</h3>
          At AIJIT, we are dedicated to delivering excellence. Our approach is
          collaborative, and we work closely with our clients to understand
          their unique challenges and objectives. By combining our industry
          knowledge with state-of-the-art technologies, we create bespoke
          solutions that drive success.
        </Typography>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item>
              <Box display="flex" alignItems="center">
                <EmailIcon color="primary" />
                <Box ml={1}>
                  <Link href="mailto:info@company.com">{contact_email}</Link>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center">
                <PhoneIcon color="primary" />
                <Box ml={1}>
                  <Typography variant="body1">{contact_num}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default AboutUs
