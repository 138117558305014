import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import FeedBackForm from './FeedBackForm' // Import the FormComponent

function Homepage() {
  const apiUrl = process.env.REACT_APP_BACKEND_URL
  const logo_path = process.env.REACT_APP_AIJIT_LOGO_PATH
  const [userData, setUserData] = useState(null)
  const home_page_image_path = process.env.REACT_APP_HOME_PAGE_IMAGE_PATH
  const walkthrough_video_link = process.env.REACT_APP_WALKTHROUGH_VIDEO_LINK
  const markdownText = `
REBA (Rapid Entire Body Assessment) is a widely-used ergonomic tool that quickly evaluates whole-body postural risks in various work environments.
This application automatically identifies posture issues and calculates the corresponding REBA score.
***
`
  useEffect(() => {
    // Fetch user data from the API endpoint
    fetch(`${apiUrl}/userservice`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setUserData(data))
      .catch((error) => console.error(error))
  }, [])
  const username = localStorage.getItem('username')
  const email = localStorage.getItem('email')
  return (
    <div className="container-home">
      <div style={{ textAlign: 'centre', width: '70%' }}>
        <Link to="/rebacalc">
          <img
            src={home_page_image_path}
            alt="Header"
            style={{ width: '100%', height: '5%' }}
          />
          <h2>Rapid Entire Body Assessment</h2>
        </Link>
      </div>
      <div style={{ textAlign: 'justify', width: '60%' }}>
        <ReactMarkdown>{markdownText}</ReactMarkdown>
        {userData && (
          <p>
            Click on the <Link to="/rebacalc">REBA-Calculator</Link> tab above
            to get started.
          </p>
        )}
        <h4>Checkout the VIDEO to understand the tool better :</h4>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src={walkthrough_video_link}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="REBA Calculator Walkthrough"
          ></iframe>
        </div>

        <FeedBackForm />
      </div>
    </div>
  )
}
export default Homepage
